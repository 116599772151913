import { useFilterStore } from 'store/filters';
import { useKeycloakStore } from 'store/keycloak';
import { usePermissionsStore } from 'store/permissions';
import { useStationStore } from 'store/stations';

export function useLogout() {
    const keycloakStore = useKeycloakStore();
    const permissionsStore = usePermissionsStore();
    const filterStore = useFilterStore();
    const stationStore = useStationStore();

    // TODO: Empty out more stores to prevent trailing data
    function logout() {
        keycloakStore.logout();
        permissionsStore.resetPermissionStore();
        filterStore.resetFilterStore();
        stationStore.resetStationStore();
    }

    return {
        logout
    };
}
